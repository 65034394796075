import React, { useEffect, useRef, useCallback, useMemo } from "react";
import { Layout, Banner } from "../../components";
import "./location-bank-styles.css";
import useFetchB2B from "../../helpers/useFetchB2B";

const ServiceStationLocator = () => {
  const { currentPage } = useFetchB2B({
    url: "b2b/engen-distributor-locator/slug",
    bySlug: true,
  });

  const mapWrapperRef = useRef(null);

  useEffect(() => {
    const locationBankMapScript = document.createElement("script");
    locationBankMapScript.src =
      "https://locationbank.net/Public/StoreLocator?id=351a1886-e123-46ee-84da-ca9472c3a4dc&layout=standard";

    document.head.appendChild(locationBankMapScript);

    const locationBankDetailsScript = document.createElement("script");
    locationBankDetailsScript.src =
      "https://storelocatorcdn.places.digital/_next/static/widgets/DetailsPage/index.js";

    document.head.appendChild(locationBankDetailsScript);
  }, []);

  const handleMutationObserver = useCallback(async () => {
    const searchInput = document.getElementById(
      "LB_BODY_SEARCH_351a1886-e123-46ee-84da-ca9472c3a4dc"
    );

    const searchForm = document.getElementById("LB_BODY_from_351a1886-e123-46ee-84da-ca9472c3a4dc");

    const searchInputGroup = searchForm?.querySelector(".input-group");

    if (searchInputGroup && !searchInputGroup.querySelector("button")) {
      const filtersButton = document.createElement("button");
      filtersButton.classList.add("filters-button");
      filtersButton.setAttribute("title", "Open Filters");

      const refineIcon = document.createElement("img");
      refineIcon.src = "./icons/refine-icon.svg";

      filtersButton.appendChild(refineIcon);
      searchInputGroup.appendChild(filtersButton);
    }

    const filterBtn = document.querySelector(".filters-button");

    if (filterBtn) {
      filterBtn.addEventListener("click", e => {
        e.preventDefault();
        const advancedSearch = document.querySelector(".ShowAdvanceSearchDetails");
        advancedSearch.classList.toggle("show");
      });
    }

    if (searchInput && searchInput.placeholder !== "Find your nearest Engen") {
      searchInput.placeholder = "Find your nearest Engen";
    }
  }, []);

  const mutationObserver = useMemo(() => new MutationObserver(handleMutationObserver), [
    handleMutationObserver,
  ]);

  useEffect(() => {
    if (mapWrapperRef.current) {
      mutationObserver.observe(mapWrapperRef.current, {
        childList: true,
        subtree: true,
      });

      return () => {
        mutationObserver.disconnect();
      };
    }
  }, [mutationObserver]);

  return (
    <Layout pageTitle="Engen & Engen Distributor Locator">
      <Banner
        title="Engen & Engen Distributor Locator"
        backgroundImage={currentPage && currentPage.image ? currentPage.image.path : ""}
      />
      <div
        id="351a1886-e123-46ee-84da-ca9472c3a4dc"
        ref={mapWrapperRef}
        className="LB_StoreLocator"
        style={{
          height: "90vh",
          width: "100%",
        }}></div>
    </Layout>
  );
};

export default ServiceStationLocator;

import React from "react";
import { Layout, NavigationBanner, Section } from "../../../components";
import { ReactComponent as Logo } from "../../../static/images/pdf-file-logo.svg";
import styles from "./AboutValue.module.scss";
import useFetchB2B from "../../../helpers/useFetchB2B";
import b2bRoutes from "../../../helpers/b2b.routes";

const AboutValue = () => {
  const { sortedSection, currentPage, pages } = useFetchB2B({ url: b2bRoutes.ABOUT });

  return (
    <Layout pageTitle="Values and Ethics" withBottomPadding={false} backButton={true}>
      {currentPage ? (
        <>
          <NavigationBanner pages={pages} activePage={currentPage} />
          <div className={styles.main}>
            {sortedSection &&
              sortedSection.map((section, index) => {
                if (index < 2) {
                  return <Section key={section.id} section={section} growSection="true" />;
                } else if (index === 2) {
                  return (
                    <div className={styles.download}>
                      <div className={styles.info}>
                        <h2>{section.header_title}</h2>
                        <div className={styles.files}>
                          {section.upload_pdf && section.upload_pdf.length
                            ? section.upload_pdf.map(({ disk_name, file_name, title, path }) => (
                                <a
                                  key={disk_name}
                                  href={path}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  download={file_name + ".pdf"}
                                  className={styles["one-file"]}>
                                  <Logo />
                                  <span>{title}</span>
                                </a>
                              ))
                            : null}
                        </div>
                        <p
                          className={styles.note}
                          dangerouslySetInnerHTML={{ __html: section.description }}></p>
                      </div>
                    </div>
                  );
                } else {
                  return <Section key={section.id} section={section} />;
                }
              })}
          </div>
        </>
      ) : null}
    </Layout>
  );
};

export default AboutValue;

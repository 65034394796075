import { useState, useEffect } from "react";
import { Layout, Banner } from "../../components";
import { useLocation } from "react-router-dom";
import "./location-bank-details-styles.css";
import useFetchB2B from "../../helpers/useFetchB2B";

const StoreDetails = () => {
  const { search } = useLocation();

  const { currentPage } = useFetchB2B({
    url: "b2b/engen-distributor-locator/slug",
    bySlug: true,
  });

  const [locationId, setLocationId] = useState(null);
  const [locationName, setLocationName] = useState(null);

  useEffect(() => {
    if (search) {
      const params = new URLSearchParams(search);
      const id = params.get("locationid");
      const storeName = params.get("loc");

      if (id.length > 0 && storeName.length > 0) {
        const locationBankDetailsScript = document.createElement("script");
        locationBankDetailsScript.src = `https://locationbank.net/Public/locationdetails?id=${id}&layout=standard`;

        setLocationId(id);
        setLocationName(storeName);
        document.head.appendChild(locationBankDetailsScript);
      }
    }
  }, [search]);

  return (
    <Layout pageTitle="Store Details">
      {locationName && (
        <Banner
          title={locationName}
          backgroundImage={currentPage && currentPage.image ? currentPage.image.path : ""}
        />
      )}
      <div>
        {locationId && (
          <div
            id={locationId}
            className="LB_LocationDetails"
            template="Template4MediaBottomLeft"
            style={{
              width: "100%",
              minHeight: "100vh",
            }}></div>
        )}
      </div>
    </Layout>
  );
};

export default StoreDetails;

import React from "react";
import styles from "./Card.module.scss";

const Card = ({ title, children, contacts = false }) => {
  return (
    <div className={styles.wrapper + " " + styles.contacts_wrapper}>
      <div className={styles.title}>{title}</div>
      <div className={styles.main}>{children}</div>
    </div>
  );
};

export default Card;

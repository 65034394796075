import React from "react";
import styles from "./Accordion.module.scss";

const Accordion = ({ title, children }) => {
  const [isOpen, setOpen] = React.useState(false);
  return (
    <div className={styles.wrapper}>
      <div
        aria-expanded={isOpen}
        className={styles.title + `${isOpen ? " " + styles.open : ""}`}
        onClick={() => setOpen(!isOpen)}>
        {title}
      </div>
      <div className={styles.item + ` ${!isOpen ? styles.collapsed : ""}`}>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default Accordion;

import React from "react";
import styles from "./LubricantRecommender.module.scss";
import { Layout, NavigationBanner } from "../../../components";
import useFetchB2B from "../../../helpers/useFetchB2B";
import b2bRoutes from "../../../helpers/b2b.routes";

const LubricantRecommender = () => {
  const { currentPage, pages } = useFetchB2B({
    url: b2bRoutes.LUBRICANTS,
  });

  return (
    <Layout pageTitle="Lube Recommender" withBottomPadding={false} backButton={true}>
      {currentPage && (
        <>
          <NavigationBanner pages={pages} activePage={currentPage} />
          <div className={styles.main}>
            <iframe
              id="lube-recommender"
              style={{ height: "100vh", width: "100%" }}
              allowTransparency="true"
              frameBorder={0}
              className={styles.frame}
              title="Lube Recommender"
              src="https://engen.ewp.earlweb.net/"
            />
          </div>
        </>
      )}
    </Layout>
  );
};

export default LubricantRecommender;

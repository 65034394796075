import React, { useState, useEffect } from "react";
import styles from "./Promotions.module.scss";
import { Layout, Banner, MediaItem } from "../../components";
import { getAllPromotionsByCountry } from "../../api/promotions.api";
import useFetchB2B from "../../helpers/useFetchB2B";

const Promotions = () => {
  const { currentPage } = useFetchB2B({
    url: "b2b/promos/slug",
    bySlug: true,
  });

  const [promotions, setPromotions] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await getAllPromotionsByCountry();

        if (response.message === "success") {
          setPromotions(response.data.promotions);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  return (
    <Layout pageTitle={currentPage ? currentPage.title : "Promotions"}>
      <Banner
        backgroundImage={currentPage && currentPage.image ? currentPage.image.path : ""}
        title={currentPage ? currentPage.title : "Promotions"}
      />
      <div className={styles.promotions}>
        {promotions.map((post, index) => (
          <div key={post.slug + index} className={styles["promotion-wrapper"]}>
            <MediaItem
              post={post}
              key={post.slug}
              imageFit={"contain"}
              page="promotions"
              slug={post.slug}
              activePost
            />
          </div>
        ))}
      </div>
    </Layout>
  );
};

export default Promotions;

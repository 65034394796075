import React from "react";
import { Layout, NavigationBanner, SectionList } from "../../components";
import styles from "./Motorists.module.scss";
import useFetchB2B from "../../helpers/useFetchB2B";
import b2bRoutes from "../../helpers/b2b.routes";

const Motorists = () => {
  const { currentPage, pages } = useFetchB2B({
    url: b2bRoutes.MOTORISTS,
    mainPage: true,
  });

  return (
    <Layout pageTitle="Motorists" withBottomPadding={false} withScrollToTop>
      {currentPage ? (
        <>
          <NavigationBanner pages={pages} activePage={currentPage} />
          <div className={styles.main}>
            <SectionList sections={currentPage.sections} />
          </div>
        </>
      ) : null}
    </Layout>
  );
};

export default Motorists;

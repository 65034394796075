import React from "react";
import { Layout, NavigationBanner, Section, ArrowLink } from "../../../components";
import styles from "./FuelDynamic.module.scss";
import useFetchB2B from "../../../helpers/useFetchB2B";
import b2bRoutes from "../../../helpers/b2b.routes";

const FuelDynamic = () => {
  const { sortedSection, currentPage, pages } = useFetchB2B({
    url: b2bRoutes.FUELS,
  });

  return (
    <Layout pageTitle="Engen Dynamic Diesel" withBottomPadding={false} backButton={true}>
      {currentPage ? (
        <>
          <NavigationBanner pages={pages} activePage={currentPage} />
          <div className={styles.main}>
            {sortedSection[0] && (
              <Section section={sortedSection[0]}>
                <div>
                  <ArrowLink
                    to="/faq"
                    text="FAQ's"
                    color={sortedSection[0].background_colour === "blue" ? "white" : "#002c90"}
                  />
                </div>
              </Section>
            )}
            {sortedSection[1] && <Section section={sortedSection[1]} />}
            <div className={styles.imageSideFlex}>
              {sortedSection[2] && <Section section={sortedSection[2]} />}
              <div className={styles.sideContent}>
                {sortedSection[3] && <Section section={sortedSection[3]} />}
                {sortedSection[4] && <Section section={sortedSection[4]} />}
              </div>
            </div>
          </div>
        </>
      ) : null}
    </Layout>
  );
};

export default FuelDynamic;

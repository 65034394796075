const b2bRoutes = {
  FUELS: "b2b/our-fuels/all",
  ABOUT: "b2b/about-engen/all",
  MOTORISTS: "b2b/motorists/all",
  ENGENCARES: "b2b/engen-cares/all",
  INDUSTRIALS: "b2b/industrial/all",
  FAQ: "b2b/faq/all",
  LUBRICANTS: "b2b/engen-lubricants/all",
  MEDIA: "b2b/media-room/all",
};

export default b2bRoutes;

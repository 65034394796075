import React from "react";
import { Layout, NavigationBanner, SectionList } from "../../../components";
import styles from "./MotoristsCafe365.module.scss";
import useFetchB2B from "../../../helpers/useFetchB2B";
import b2bRoutes from "../../../helpers/b2b.routes";

const MotoristsCafe365 = () => {
  const { sortedSection, currentPage, pages } = useFetchB2B({
    url: b2bRoutes.MOTORISTS,
  });

  return (
    <Layout pageTitle="Engen Cafe 365" withBottomPadding={false} backButton={true}>
      {currentPage ? (
        <>
          <NavigationBanner pages={pages} activePage={currentPage} />
          <div className={styles.main}>
            <SectionList sections={sortedSection} />
          </div>
        </>
      ) : null}
    </Layout>
  );
};

export default MotoristsCafe365;

import React from "react";
import { Layout, NavigationBanner, SectionList } from "../../../components";
import styles from "./AboutSupply.module.scss";
import useFetchB2B from "../../../helpers/useFetchB2B";
import b2bRoutes from "../../../helpers/b2b.routes";

const AboutSupply = () => {
  const { currentPage, pages } = useFetchB2B({ url: b2bRoutes.ABOUT });

  return (
    <Layout pageTitle="Supply and Distribution" withBottomPadding={false} backButton={true}>
      {currentPage ? (
        <>
          <NavigationBanner pages={pages} activePage={currentPage} />
          <div className={styles.main}>
            <SectionList sections={currentPage.sections} />
          </div>
        </>
      ) : null}
    </Layout>
  );
};

export default AboutSupply;

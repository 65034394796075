import React, { useState, useEffect } from "react";
import styles from "./FaqAdBlue.module.scss";
import { Layout, NavigationBanner } from "../../../components";
import { getFaqBySlug } from "../../../api/faq.api";
import useFetchB2B from "../../../helpers/useFetchB2B";
import b2bRoutes from "../../../helpers/b2b.routes";

const FaqAdBlue = ({ match }) => {
  const { currentPage, pages } = useFetchB2B({
    url: b2bRoutes.FAQ,
  });

  const [faq, setFaq] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await getFaqBySlug(match.url.split("/")[2]);

        if (response.message === "success") {
          setFaq(response.data.faq);
        } else {
          console.error(response.message);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [match.url]);

  return (
    <Layout pageTitle={`${currentPage ? currentPage.title : ""}`} backButton={true}>
      {currentPage && <NavigationBanner pages={pages} activePage={currentPage} />}
      {faq && (
        <section>
          {faq.description && (
            <div
              className={styles.tabContent}
              dangerouslySetInnerHTML={{ __html: faq.description }}
            />
          )}
        </section>
      )}
    </Layout>
  );
};

export default FaqAdBlue;

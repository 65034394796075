import React from "react";
import { Layout, NavigationBanner, Section, SectionList } from "../../../components";
import styles from "./EngenCaresTransformation.module.scss";
import useFetchB2B from "../../../helpers/useFetchB2B";
import b2bRoutes from "../../../helpers/b2b.routes";

const EngenCaresTransformation = () => {
  const { sortedSection, currentPage, pages } = useFetchB2B({
    url: b2bRoutes.ENGENCARES,
  });

  return (
    <Layout pageTitle="Transformation" withBottomPadding={false} backButton={true}>
      {currentPage ? (
        <>
          <NavigationBanner pages={pages} activePage={currentPage} />
          <div className={styles.main}>
            {sortedSection[0] && <Section section={sortedSection[0]} />}
            {sortedSection[1] && <Section section={sortedSection[1]} pdfType="arrowIcon" contain />}
            {sortedSection[2] && <Section section={sortedSection[2]} />}
            {sortedSection[3] && <Section section={sortedSection[3]} />}
            {sortedSection[4] && <Section section={sortedSection[4]} />}
            {sortedSection[5] && <Section section={sortedSection[5]} pdfType="arrowIcon" />}
          </div>
          {sortedSection[6] && <SectionList sections={sortedSection.slice([6])} />}
        </>
      ) : null}
    </Layout>
  );
};

export default EngenCaresTransformation;

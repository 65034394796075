import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { get } from "../services";

const useFetchB2B = ({ url, mainPage, bySlug }) => {
  const location = useLocation();

  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const [sortedSection, setSortedSection] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const response = await get(url);

      if (response.message === "success") {
        setPages(response.data.b2b_all);

        const pageFind = mainPage
          ? response.data.b2b_all[
              response.data.b2b_all.findIndex(({ slug }) => slug.includes("main-page"))
            ]
          : bySlug
          ? response.data.b2b
          : response.data.b2b_all.find(b2b => b2b.link_url === location.pathname);

        setCurrentPage(pageFind);
      } else {
        console.error(response.message);
      }
    } catch (error) {
      console.error(error);
    }
  }, [bySlug, location.pathname, mainPage, url]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (currentPage) {
      const sortedSection =
        currentPage && currentPage.sections && currentPage.sections.length
          ? currentPage.sections.sort((a, b) => a.sort_order - b.sort_order)
          : [];

      setSortedSection(sortedSection);
    }
  }, [currentPage]);

  return { sortedSection, currentPage, pages };
};

export default useFetchB2B;

const findClosesLocation = (locations, place, googleMaps, allInfo = false) => {
  if (!place.lng) {
    console.error("No user location at findClosestLocation.js", place);
  }
  const closestStation = locations.sort(
    (a, b) =>
      googleMaps.geometry.spherical.computeDistanceBetween(
        new googleMaps.LatLng(parseFloat(a.latitude), parseFloat(a.longitude)),
        new googleMaps.LatLng(place.lat, place.lng)
      ) -
      googleMaps.geometry.spherical.computeDistanceBetween(
        new googleMaps.LatLng(parseFloat(b.latitude), parseFloat(b.longitude)),
        new googleMaps.LatLng(place.lat, place.lng)
      )
  );
  if (closestStation[0] && allInfo) {
    return closestStation[0];
  }

  let closestStationPosition = {};
  if (closestStation[0] && !allInfo) {
    closestStationPosition = {
      lat: parseFloat(closestStation[0].latitude),
      lng: parseFloat(closestStation[0].longitude),
    };
  }

  return closestStationPosition;
};

export default findClosesLocation;

import React from "react";
import { Layout, NavigationBanner, Section, SectionList } from "../../../components";
import styles from "./EngenCaresHseq.module.scss";
import useFetchB2B from "../../../helpers/useFetchB2B";
import b2bRoutes from "../../../helpers/b2b.routes";

const EngenCaresHseq = () => {
  const { sortedSection, currentPage, pages } = useFetchB2B({
    url: b2bRoutes.ENGENCARES,
  });

  return (
    <Layout pageTitle="HSEQ" withBottomPadding={false} backButton={true}>
      {currentPage ? (
        <>
          <NavigationBanner pages={pages} activePage={currentPage} />
          <div className={styles.main}>
            {sortedSection[0] && <Section section={sortedSection[0]} />}
            <div className={styles.columnedBlock}>
              {sortedSection[1] && <Section section={sortedSection[1]} />}
              {sortedSection[2] && <Section section={sortedSection[2]} />}
            </div>
            {sortedSection[3] && <Section section={sortedSection[3]} />}

            {sortedSection[4] && <Section section={sortedSection[4]} />}
            {sortedSection[5] && <Section section={sortedSection[5]} />}
          </div>
          {sortedSection[6] && <SectionList sections={sortedSection.slice([6])} />}
        </>
      ) : null}
    </Layout>
  );
};

export default EngenCaresHseq;

import * as Yup from "yup";

const contactSchema = Yup.object().shape({
  name: Yup.string().required("This is a required field"),
  email: Yup.string()
    .email("Email Should be valid")
    .required("This is a required field"),
  telephone: Yup.string().required(),
  message: Yup.string().required("This is a required field"),
  department: Yup.string().required("This is a required field"),
});

export default contactSchema;

import React from "react";
import styles from "./IndustrialView.module.scss";
import {
  Layout,
  NavigationBanner,
  Card,
  Button,
  CardCollapsible,
  SectionList,
} from "../../../components";
import useXBreakpoint from "../../../hooks/useXBreakpoint";
import useFetchB2B from "../../../helpers/useFetchB2B";
import b2bRoutes from "../../../helpers/b2b.routes";

const IndustrialView = () => {
  const { currentPage, pages } = useFetchB2B({
    url: b2bRoutes.INDUSTRIALS,
  });

  const isMobile = useXBreakpoint(1024);

  return (
    <Layout
      pageTitle={`Industrial ${currentPage ? currentPage.title : ""}`}
      withBottomPadding={false}
      backButton={true}>
      {currentPage ? (
        <>
          <NavigationBanner pages={pages} activePage={currentPage} />
          <section className={styles.sections}>
            <SectionList sections={currentPage ? currentPage.sections : []} />
          </section>
          {currentPage && currentPage.products && currentPage.products.length ? (
            <>
              <section className={styles.products}>
                {currentPage &&
                  currentPage.products &&
                  currentPage.products.length &&
                  currentPage.products.map(product => {
                    if (isMobile) {
                      return (
                        <div key={product.title} className={styles.mobileProduct}>
                          <CardCollapsible title={product.title}>
                            <div
                              dangerouslySetInnerHTML={{ __html: product.description }}
                              className={styles.mobileProductDescription}
                            />
                          </CardCollapsible>
                        </div>
                      );
                    }
                    return (
                      <div key={product.title} className={styles.productWrapper}>
                        <Card title={product.title}>
                          <div
                            className={styles.productDescription}
                            dangerouslySetInnerHTML={{ __html: product.description }}
                          />
                        </Card>
                      </div>
                    );
                  })}
              </section>
              <div className={styles.viewAll}>
                <Button text="View All" href="/products" />
              </div>
            </>
          ) : null}
        </>
      ) : null}
    </Layout>
  );
};

export default IndustrialView;

import React, { useState, useEffect } from "react";
import styles from "./MediaRoom.module.scss";
import {
  Carousel,
  Layout,
  MediaCarouselItem,
  Button,
  Socials,
  NavigationBanner,
  SectionList,
} from "../../components";
import { getAllMediaReleases } from "../../api/news.api";
import useFetchB2B from "../../helpers/useFetchB2B";
import b2bRoutes from "../../helpers/b2b.routes";

const TwitterFeed = React.lazy(() => import("./TwitterFeed"));

const MediaRoom = ({ history }) => {
  const { currentPage, pages } = useFetchB2B({
    url: b2bRoutes.MEDIA,
    mainPage: true,
  });

  const [mediaReleases, setMediaReleases] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await getAllMediaReleases();

        if (response.message === "success") {
          setMediaReleases(response.data["media-release"].data);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://platform.twitter.com/widgets.js";
    script.async = true;

    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleViewAllReleases = () => () => history.push("media/media-release");

  return (
    <Layout pageTitle="Media" withBottomPadding={false}>
      {currentPage ? <NavigationBanner pages={pages} activePage={currentPage} /> : null}
      <div className={styles["media-contacts"]}>
        <h2 className={styles["media-contacts-title"]}>Media Contacts</h2>
        <div className={styles["media-contacts-item"]}>
          <p className={styles["media-contacts-item-block"]}>
            <span className={styles["media-contacts-item-title"]}>Gavin Smith</span>
            <span className={styles["media-contacts-item-subtitle"]}>
              External Communications Manager
            </span>
          </p>
          <p className={styles["media-contacts-item-block"]}>
            <a href="tel:+2721 403 4312" className={styles["media-contacts-item-contact"]}>
              Tel: +2721 403 4312
            </a>
            <a
              href="mailto:gavin.smith@engenoil.com"
              className={styles["media-contacts-item-contact"]}>
              Email: gavin.smith@engenoil.com
            </a>
          </p>
        </div>
      </div>
      {currentPage && currentPage.sections && currentPage.sections.length ? (
        <div className={styles.sections}>
          <SectionList sections={currentPage.sections} />
        </div>
      ) : null}
      <h2 className={styles["carousel-title"]}>Latest Media Releases</h2>
      <div>
        {mediaReleases && mediaReleases.length ? (
          <Carousel
            slides={mediaReleases.sort((a, b) => {
              return new Date(b.uploaded_date) - new Date(a.uploaded_date);
            })}
            render={(slides, activeSlide) => {
              return slides.map((post, index) => (
                <MediaCarouselItem
                  page="media"
                  tag={post.tag}
                  post={post}
                  key={post.id}
                  activePost={activeSlide === index}
                />
              ));
            }}
          />
        ) : null}
      </div>
      <div className={styles["button-centered"]}>
        <Button
          style={{ margin: "0 auto", width: "fit-content" }}
          text="View All"
          onClick={handleViewAllReleases()}
        />
      </div>
      <div className={styles["social-media-feed"]}>
        <h2 className={styles["carousel-title"]}>Social Media Feed</h2>
        <div className={styles.feed}>
          <FacebookPlugin width={window.innerWidth < 500 ? window.innerWidth : 500} />
          <React.Suspense fallback={<div>Loading...</div>}>
            <TwitterFeed />
          </React.Suspense>
        </div>

        <div className={styles["social-media-feed"]}>
          <h2 className={`${styles["carousel-title"]} ${styles["socials"]}`}>Follow Us On:</h2>
          <div className={styles["button-centered"]} style={{ paddingTop: 0 }}>
            <Socials />
          </div>
        </div>
      </div>
    </Layout>
  );
};

const FacebookPlugin = ({ width = 500 }) => {
  return (
    <iframe
      title="facebook"
      src={`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FEngenSA%2F&tabs=timeline&width=${width}&height=700&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId`}
      width={JSON.stringify(width)}
      height="700"
      style={{ border: "none", overflow: "hidden" }}
      scrolling="no"
      frameborder="0"
      allowTransparency="true"
      allow="encrypted-media"></iframe>
  );
};

export default MediaRoom;

import React from "react";
import { Layout, Section, NavigationBanner } from "../../../components";
import styles from "./AboutManufacturing.module.scss";
import useFetchB2B from "../../../helpers/useFetchB2B";
import b2bRoutes from "../../../helpers/b2b.routes";

const Manufacturing = () => {
  const { sortedSection, currentPage, pages } = useFetchB2B({ url: b2bRoutes.ABOUT });

  return (
    <Layout withBottomPadding={false} pageTitle="Manufacturing">
      {currentPage ? (
        <>
          <NavigationBanner pages={pages} activePage={currentPage} />
          <div className={styles.main}>
            <div className={styles.imageSideFlex}>
              {sortedSection[0] && <Section section={sortedSection[0]} />}
              <div className={styles.sideContent}>
                {sortedSection[1] && <Section section={sortedSection[1]} />}
                {sortedSection[2] && <Section section={sortedSection[2]} />}
              </div>
            </div>
            {sortedSection[3] && <Section section={sortedSection[3]} />}
            {sortedSection[4] && <Section section={sortedSection[4]} />}
          </div>
        </>
      ) : null}
    </Layout>
  );
};

export default Manufacturing;

import React, { useEffect, useState } from "react";
import { Layout, NavigationBanner, Tabs } from "../../../components";
import styles from "./LubricantDisposal.module.scss";
import useFetchB2B from "../../../helpers/useFetchB2B";
import b2bRoutes from "../../../helpers/b2b.routes";

const LubricantDisposal = () => {
  const { currentPage, pages } = useFetchB2B({
    url: b2bRoutes.LUBRICANTS,
  });

  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    if (currentPage && currentPage.sections && currentPage.sections.length) {
      setActiveTab(currentPage.sections[0]);
    }
  }, [currentPage]);

  const handleChangeActiveTab = value => {
    setActiveTab(currentPage.sections.find(({ slug }) => slug === value));
  };

  const tabs =
    currentPage && currentPage.sections && currentPage.sections.length
      ? currentPage.sections.map(({ slug, header_title }) => ({
          id: slug,
          value: slug,
          label: header_title,
        }))
      : [];

  return (
    <Layout pageTitle="Lubricants Disposal Guide" withBottomPadding={false} backButton={true}>
      {currentPage ? (
        <>
          <NavigationBanner pages={pages} activePage={currentPage} />
          <div className={styles.main}>
            {activeTab ? (
              <>
                <Tabs tabs={tabs} activeTab={activeTab.slug} action={handleChangeActiveTab} />
                <div
                  className={styles.tabContent}
                  dangerouslySetInnerHTML={{ __html: activeTab.description }}
                />
              </>
            ) : null}
          </div>
        </>
      ) : null}
    </Layout>
  );
};

export default LubricantDisposal;

import React from "react";
import { Layout, NavigationBanner, SectionList } from "../../components";
import styles from "./Industrial.module.css";
import useFetchB2B from "../../helpers/useFetchB2B";
import b2bRoutes from "../../helpers/b2b.routes";

const Industrial = () => {
  const { currentPage, pages } = useFetchB2B({
    url: b2bRoutes.INDUSTRIALS,
    mainPage: true,
  });

  return (
    <Layout pageTitle="Business Solutions" withBottomPadding={false}>
      {currentPage && (
        <>
          <NavigationBanner pages={pages} activePage={currentPage} />
          <div className={styles.main}>
            <SectionList sections={currentPage.sections} />
          </div>
        </>
      )}
    </Layout>
  );
};

export default Industrial;

import React from "react";
import { Layout, NavigationBanner, SectionList } from "../../components";
import styles from "./Fuels.module.scss";
import useFetchB2B from "../../helpers/useFetchB2B";
import b2bRoutes from "../../helpers/b2b.routes";

const Fuels = () => {
  const { currentPage, pages } = useFetchB2B({
    url: b2bRoutes.FUELS,
    mainPage: true,
  });

  return (
    <Layout pageTitle="Our Fuels" withBottomPadding={false} withScrollToTop>
      {currentPage && (
        <>
          <NavigationBanner pages={pages} activePage={currentPage} />
          <div className={styles.main}>
            <SectionList sections={currentPage.sections} contain={true} />
          </div>
        </>
      )}
    </Layout>
  );
};

export default Fuels;

import React from "react";
import { Layout, NavigationBanner, Section, Button, SectionList } from "../../../components";
import styles from "./MotoristsFranchising.module.scss";
import useFetchB2B from "../../../helpers/useFetchB2B";
import b2bRoutes from "../../../helpers/b2b.routes";

const MotoristsFranchising = () => {
  const { sortedSection, currentPage, pages } = useFetchB2B({
    url: b2bRoutes.MOTORISTS,
  });

  return (
    <Layout pageTitle="Franchising" withBottomPadding={false} backButton={true}>
      {currentPage ? (
        <>
          <NavigationBanner pages={pages} activePage={currentPage} />
          <div className={styles.main}>
            {sortedSection[0] && <Section section={sortedSection[0]} />}
            {sortedSection[1] && (
              <Section section={sortedSection[1]}>
                <a
                  style={{ display: "block", marginTop: "7rem" }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://engen-franchising.erecruit.co/candidateapp/Jobs/Browse">
                  <Button text="Franchise Opportunities" />
                </a>
              </Section>
            )}
            {sortedSection[2] && <Section section={sortedSection[2]} imagePosition="top" />}

            {sortedSection[3] && <Section section={sortedSection[3]} />}
          </div>

          {sortedSection[4] && <SectionList sections={sortedSection.slice([4])} />}
        </>
      ) : null}
    </Layout>
  );
};

export default MotoristsFranchising;

import React, { useState, useEffect } from "react";
import { withFormik } from "formik";
import didYouMean from "didyoumean";
import styles from "./ContactForm.module.scss";
import { FormikTextInput, Checkbox, FormikTextarea, Select, Button } from "../index";
import contactSchema from "./ContactFormValidation";
import { sendMessage, getQueryTypes } from "../../api/contacts.api";

const initialValues = () => ({
  name: "",
  email: "",
  telephone: "",
  department: "",
  country: "",
  real_department: "",
  message: "",
  agreeCommunication: false,
});

const list = [
  "@gmail.com",
  "@outlook.com",
  "@aol.com",
  "@yahoo.com",
  "@mail.ru",
  "@icloud.com",
  "@mail.com",
  "@zoho.com",
  "@yandex.ru",
];

const ContactForm = props => {
  const [domain, setDomain] = useState("");
  const [queryTypes, setQueryTypes] = useState([]);
  const [isSentSuccess, setIsSentSuccess] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [realDep, setRealDep] = useState("");

  useEffect(() => {
    (async () => {
      try {
        const response = await getQueryTypes();

        if (response.message === "success") {
          setQueryTypes(response.data.query_types);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  const getStringBeforeDomain = input => {
    const domainIndex = input.indexOf("@");
    if (domainIndex !== -1) {
      return input.substring(0, domainIndex);
    } else {
      return "";
    }
  };

  const validateEmailDomain = value => {
    const domainIndex = value.indexOf("@");
    let currentDomain;
    if (domainIndex !== -1) {
      currentDomain = value && value.slice(domainIndex);
      if (currentDomain !== didYouMean(currentDomain, list)) {
        setDomain(didYouMean(currentDomain, list));
      } else {
        setDomain("");
      }
    }
    props.setFieldValue("email", value);
  };

  const onSubmit = async event => {
    event.preventDefault();

    setInProgress(true);

    const {
      name,
      email,
      message,
      department,
      telephone,
      agreeCommunication,
      country,
      real_department,
      main_department,
    } = props.values;

    const foundDepartment = queryTypes.find(({ slug }) => slug === department);
    const getDepartmentId = () => {
      if (main_department) {
        return foundDepartment.country_email.departments[main_department].id;
      } else if (real_department && country) {
        return foundDepartment.country_email.countries[country].departments.find(
          ({ slug }) => slug === real_department
        ).id;
      }
      return undefined;
    };

    const getCountryId = () => {
      if (country) {
        return foundDepartment.country_email.countries[country].id;
      }
      return undefined;
    };

    try {
      const response = await sendMessage({
        name,
        email,
        message,
        query_type: department,
        mobile: telephone,
        agree_to_comms: agreeCommunication,
        country_id: getCountryId(),
        department_id: getDepartmentId(),
      });
      if (response.data.response.code === 200) {
        setInProgress(false);
        setIsSentSuccess(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const queryTypesOptions = queryTypes.map(({ id, title, slug, ...args }) => ({
    value: slug,
    label: title,
    title,
    ...args,
  }));

  const foundQuery = queryTypes.find(({ slug }) => slug === props.values.department);
  let countriesOptions;
  if (foundQuery) {
    const obj = foundQuery.country_email.countries;
    countriesOptions = Object.keys(obj).map(i => obj[i]);
    countriesOptions.map(object => (object["value"] = object.slug));
    countriesOptions.map(object => (object["label"] = object.title));
  }

  useEffect(() => {
    if (props.values.country.length > 0 && countriesOptions.length > 0) {
      const countryObject = countriesOptions.find(option => option.value === props.values.country);
      if (countryObject) {
        const obj = countryObject.departments;
        obj.map(object => (object["value"] = object.slug));
        obj.map(object => (object["label"] = object.title));
        setRealDep(obj);
      }
    }
    //eslint-disable-next-line
  }, [props.values.country]);
  if (!countriesOptions && props.values.country.length > 0) {
    props.setFieldValue("country", "");
  } else if (countriesOptions && props.values.country.length > 0 && countriesOptions.length === 0) {
    props.setFieldValue("country", "");
  }

  if (!realDep && props.values.real_department.length > 0) {
    props.setFieldValue("real_department", "");
  } else if (realDep && props.values.real_department.length > 0 && realDep.length === 0) {
    props.setFieldValue("real_department", "");
  }

  const foundQ = queryTypes.find(({ slug }) => slug === props.values.department);
  let mainDepartments;
  if (foundQ) {
    let obj = foundQ.country_email.departments;
    mainDepartments = Object.keys(obj).map(i => obj[i]);
    mainDepartments.map(object => (object["value"] = object.slug));
    mainDepartments.map(object => (object["label"] = object.title));
  }

  return (
    <form className={styles.wrapper}>
      <div className={styles["text-input"]}>
        <FormikTextInput
          onClick={() => setIsSentSuccess(false)}
          name="name"
          placeholder="Name*"
          formik={props}
        />

        <div className={styles["email-field-wrapper"]}>
          <FormikTextInput
            name="email"
            placeholder="Email address*"
            onChange={e => {
              validateEmailDomain(e.target.value);
              setIsSentSuccess(false);
            }}
            formik={props}
          />
          {domain ? (
            <div
              className={styles["did-you-mean"]}
              onClick={() => {
                props.setFieldValue(
                  "email",
                  `${getStringBeforeDomain(props.values.email)}${domain}`
                );
                setDomain("");
              }}>
              Did you mean {getStringBeforeDomain(props.values.email)}
              {domain}?
            </div>
          ) : (
            ""
          )}
        </div>

        <FormikTextInput
          name="telephone"
          placeholder="Mobile number (optional)"
          value={props.values.telephone}
          onChange={e => {
            props.setFieldValue("telephone", e.target.value.replace(/\s/g, ""));
            setIsSentSuccess(false);
          }}
          formik={props}
        />

        <Select
          name="department"
          placeholder="Nature of query"
          options={queryTypesOptions}
          isSearchable={true}
          value={queryTypesOptions.find(type => type.value === props.values.department)}
          onChange={e => {
            props.setFieldValue("department", e.value);
            setRealDep("");
            props.setFieldValue("country", "");
          }}
          key={props.values.department}
        />
        {countriesOptions && countriesOptions.length > 0 && (
          <Select
            name="country"
            placeholder="Country"
            options={countriesOptions}
            isSearchable={true}
            value={
              props.values.country
                ? countriesOptions.find(type => type.value === props.values.country)
                : "Country"
            }
            onChange={e => props.setFieldValue("country", e.value)}
            key={props.values.country}
          />
        )}

        {countriesOptions && countriesOptions.length > 0 && realDep.length > 0 && (
          <Select
            name="real_department"
            placeholder="Department"
            options={realDep}
            isSearchable={true}
            value={realDep.find(type => type.value === props.values.real_department)}
            onChange={e => props.setFieldValue("real_department", e.value)}
            key={props.values.real_department}
          />
        )}
        {mainDepartments && mainDepartments.length > 0 && (
          <Select
            name="main_department"
            placeholder="Department"
            options={mainDepartments}
            isSearchable={true}
            value={mainDepartments.find(type => type.value === props.values.main_department)}
            onChange={e => props.setFieldValue("main_department", e.value)}
            key={props.values.main_department}
          />
        )}

        <FormikTextarea
          onChange={() => setIsSentSuccess(false)}
          name="message"
          placeholder="Message*"
          formik={props}
        />

        <Checkbox
          onChange={() =>
            props.setFieldValue("agreeCommunication", !props.values.agreeCommunication)
          }
          name="agreeCommunication"
          id="agreeCommunication"
          label="I agree to receiving communication from Engen pertaining to my query only."
          round
        />
      </div>

      <div className={styles["submit-button"]}>
        <Button
          disabled={!props.isValid || isSentSuccess || inProgress}
          onClick={onSubmit}
          isSuccess={isSentSuccess}
          text={`${isSentSuccess ? "Sent" : inProgress ? "Sending Email" : "Send message"}`}
        />
      </div>
    </form>
  );
};

export default withFormik({
  validationSchema: contactSchema,
  mapPropsToValues: () => initialValues(),
  isInitialValid: false,
})(ContactForm);

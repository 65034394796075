import React from "react";
import { Layout, NavigationBanner, SectionList } from "../../../components";
import useFetchB2B from "../../../helpers/useFetchB2B";
import b2bRoutes from "../../../helpers/b2b.routes";

const MotoristsRewards = () => {
  const { currentPage, pages } = useFetchB2B({
    url: b2bRoutes.MOTORISTS,
  });

  return (
    <Layout pageTitle="Rewards" withBottomPadding={false} backButton={true}>
      {currentPage ? (
        <>
          <NavigationBanner pages={pages} activePage={currentPage} />
          <SectionList sections={currentPage.sections} />
        </>
      ) : null}
    </Layout>
  );
};

export default MotoristsRewards;

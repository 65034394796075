import React, { useState, useEffect } from "react";
import Carousel from "./carousel";
import Dots from "./indicator-dots";
import styles from "./BannerCarousel.module.scss";
import { BannerForCarousel } from "../index";
import { getAllCarousel } from "../../api/carousel.api";
import useXBreakpoint from "../../hooks/useXBreakpoint";

const BannerCarousel = () => {
  const [banners, setBanners] = useState([]);
  const [titleHeight, setTitleHeight] = useState(0);
  const isMobile = useXBreakpoint(1024);

  useEffect(() => {
    (async () => {
      try {
        const response = await getAllCarousel();

        if (response.message === "success") {
          setBanners(response.data.carousel);
        } else {
          console.error(response.message);
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  return (
    <div style={{ height: "100vh" }}>
      <Carousel
        titleHeight={titleHeight}
        loop
        widgets={!isMobile ? [Dots] : null}
        auto
        axis="x"
        duration={200}
        className={styles.background}>
        {banners
          ? banners.map(banner => (
              <BannerForCarousel
                key={banner.id}
                title={banner.name}
                backgroundImage={banner.image.path}
                isCarouselBanner={true}
                subtitle={banner.link_title}
                link={banner.link}
                setterTitleHeight={setTitleHeight}
              />
            ))
          : null}
      </Carousel>
    </div>
  );
};

export default BannerCarousel;

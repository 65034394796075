import React from "react";
import { Layout, NavigationBanner, Section, SectionList } from "../../components";
import styles from "./Lubricants.module.scss";
import useFetchB2B from "../../helpers/useFetchB2B";
import b2bRoutes from "../../helpers/b2b.routes";

const Lubricants = () => {
  const { sortedSection, currentPage, pages } = useFetchB2B({
    url: b2bRoutes.LUBRICANTS,
    mainPage: true,
  });

  return (
    <Layout pageTitle="Lubricants" withBottomPadding={false} withScrollToTop>
      {currentPage && (
        <>
          <NavigationBanner pages={pages} activePage={currentPage} />
          <div className={styles.main}>
            <div className={styles.columnedBlock}>
              {sortedSection[0] && <Section section={sortedSection[0]} />}
              {sortedSection[1] && <Section section={sortedSection[1]} />}
              {sortedSection[2] && <Section section={sortedSection[2]} />}
            </div>
            <div className={styles.columnedBlock}>
              {sortedSection[3] && <Section section={sortedSection[3]} />}
              {sortedSection[4] && <Section section={sortedSection[4]} />}
            </div>
          </div>
          {sortedSection[5] && <SectionList sections={sortedSection.slice([5])} />}
        </>
      )}
    </Layout>
  );
};

export default Lubricants;

import React, { useEffect, useState } from "react";
import Cookie from "js-cookie";
import styles from "./CookieNotification.module.scss";
import { Button } from "../index";

const CookieNotification = ({ onAccept }) => {
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    const cookieAccepted = Cookie.get("cookie-accept");
    if (!cookieAccepted) {
      setShowNotification(true);
    }
  }, []);

  const handleAcceptCookie = () => () => {
    Cookie.set("cookie-accept", true, { expires: 365 });
    setShowNotification(false);
    onAccept();
  };

  if (!showNotification) return null;

  return (
    <div className={styles.wrapper}>
      <div className={styles.block}>
        <p className={styles["text-warning"]}>
          <a href="/services/privacy-notice">
            This website uses cookies to enhance the user experience.{" "}
            <span style={{ textDecoration: "underline" }}>View our Privacy Policy</span>.
          </a>
        </p>
        <div className={styles.buttons}>
          <Button onClick={handleAcceptCookie()} text="Accept" id="cookie-notice-accept" />
          <Button onClick={handleAcceptCookie()} text="Close" id="cookie-notice-close" />
        </div>
      </div>
    </div>
  );
};

export default CookieNotification;

import React, { useState, useRef, useEffect } from "react";
import styles from "./BannerForCarousel.module.scss";
import { Link } from "react-router-dom";
import bannerStyles from "../NavigationBanner/NavigationBanner.module.scss";

const BannerForCarousel = ({
  title,
  backgroundImage,
  underTitle,
  isCarouselBanner = false,
  subtitle,
  link,
  setterTitleHeight,
}) => {
  const [imgLoaded, setImageLoaded] = useState(false);
  const [titleHeight, setTitleHeight] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const titleRowsCount = title.match(new RegExp("/", "g")).length;

  const onImageLoad = () => {
    setImageLoaded(true);
  };

  useEffect(() => {
    const handleResize = () => {
      setTitleHeight(titleRef.current.clientHeight);
      setterTitleHeight(titleRef.current.clientHeight);
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    setTitleHeight(titleRef.current.clientHeight);
    setterTitleHeight(titleRef.current.clientHeight);

    return () => window.removeEventListener("resize", handleResize);
    //eslint-disable-next-line
  }, []);

  const titleRef = useRef();

  let caclHeight = () => {
    if (titleHeight < 110 && width > 600) {
      return (height * 27) / 100 + titleHeight;
    } else {
      return (height * 17) / 100 + titleHeight;
    }
  };
  function createMarkup(title) {
    return { __html: title };
  }

  return (
    <div
      className={`${styles[underTitle ? "wrapper-with-under-title" : "wrapper"]} ${
        styles[isCarouselBanner ? "carousel-wrapper" : ""]
      }`}>
      <div className={styles.wrapperImg}>
        <img
          onLoad={onImageLoad}
          className={`${styles.background} ${imgLoaded ? styles.faded : ""}`}
          src={backgroundImage}
          alt={title}
        />
        <h1
          ref={titleRef}
          style={
            titleHeight > 110 && width > 600
              ? { background: "transparent", top: "17%" }
              : { background: "transparent", top: "27%" }
          }
          className={`${bannerStyles.title} ${
            titleRowsCount === 3 ? styles.thee_rows_title : null
          } ${titleRowsCount === 4 ? styles.four_rows_title : null}`}
          dangerouslySetInnerHTML={createMarkup(title)}></h1>

        {isCarouselBanner ? (
          link.includes("http") ? (
            <a rel="noopener noreferrer" target="_blank" href={link}>
              <div
                style={titleHeight > 109 && width > 600 ? { top: caclHeight + "px" } : {}}
                className={`${styles.subtitle_wrapper} ${
                  height < 830 && width > 1100 && width > 1500 && titleHeight > 120
                    ? styles.biggerMarginTop
                    : null
                } ${
                  height < 830 && width > 1100 && titleHeight <= 120 ? styles.middleMarginTop : null
                }
                  ${
                    height < 830 && width > 1100 && width < 1500 && titleHeight >= 120
                      ? styles.macbookTop
                      : null
                  } 
                  ${
                    titleRowsCount === 1
                      ? styles.one_row_subtitle_wrapper
                      : titleRowsCount === 2
                      ? styles.two_rows_subtitle_wrapper
                      : titleRowsCount === 3
                      ? styles.three_rows_subtitle_wrapper
                      : titleRowsCount === 4
                      ? styles.four_rows_subtitle_wrapper
                      : null
                  }
                `}>
                <span className={styles.subtitle}>{subtitle}</span>
                <span className={styles.arrow} />
              </div>
            </a>
          ) : (
            <Link to={link}>
              <div
                className={`${styles.subtitle_wrapper} 
                  
                  ${height < 800 && width > 1100 && width > 1500 ? styles.biggerMarginTop : null} ${
                  height < 830 && width > 1100 && titleHeight <= 120 ? styles.middleMarginTop : null
                } ${
                  height < 830 && width > 1100 && width < 1500 && titleHeight >= 120
                    ? styles.macbookTop
                    : null
                }
                ${
                  titleRowsCount === 1
                    ? styles.one_row_subtitle_wrapper
                    : titleRowsCount === 2
                    ? styles.two_rows_subtitle_wrapper
                    : titleRowsCount === 3
                    ? styles.three_rows_subtitle_wrapper
                    : titleRowsCount === 4
                    ? styles.four_rows_subtitle_wrapper
                    : null
                }
                `}>
                <span className={styles.subtitle}>{subtitle}</span>
                <span className={styles.arrow} />
              </div>
            </Link>
          )
        ) : null}
      </div>

      {underTitle && (
        <div className={styles["under-title"]}>
          <span>{underTitle}</span>
        </div>
      )}
    </div>
  );
};

export default BannerForCarousel;

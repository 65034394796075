import React from "react";
import { NavigationBanner, Layout, SectionList } from "../../components";
import useFetchB2B from "../../helpers/useFetchB2B";
import b2bRoutes from "../../helpers/b2b.routes";

const About = () => {
  const { currentPage, pages } = useFetchB2B({
    url: b2bRoutes.ABOUT,
    mainPage: true,
  });

  return (
    <Layout pageTitle="About" withBottomPadding={false} withScrollToTop>
      {currentPage && (
        <>
          <NavigationBanner pages={pages} activePage={currentPage} />
          <SectionList sections={currentPage.sections} />
        </>
      )}
    </Layout>
  );
};

export default About;

import React, { useEffect, useState } from "react";
import qs from "query-string";
import styles from "./Products.module.scss";
import {
  Layout,
  Product,
  Banner,
  Button,
  Loader,
  SearchInput,
  ProductsFilter,
  RefineIcon,
} from "../../components";
import productBannerImg from "../../static/images/products-background.png";
import {
  getProductsByPage,
  getFiltersProductType,
  getFiltersCategory,
  getFiltersLubricantTypeById,
  getFiltersLubricantType,
  getFiltersBrand,
  getFiltersChemicalTypeById,
  getFiltersChemicalType,
  getFiltersChemicalType3ById,
} from "../../api/products.api";
import useDebounce from "../../hooks/useDebounce";
import recursivelyFindAndAdd from "../../lib/recursivelyFindAndAdd";
import useXBreakpoint from "../../hooks/useXBreakpoint";

const filterSteps = [
  {
    title: "Fuel",
    steps: [
      {
        title: "Area of Use",
        apiRequest: getFiltersCategory,
        urlParam: "category_id",
      },
    ],
    urlParam: "product_type_id",
  },
  {
    title: "Lubricant",
    steps: [
      {
        title: "Brand",
        apiRequest: getFiltersBrand,
        urlParam: "brand_id",
      },
      {
        title: "Area of Use",
        apiRequest: getFiltersLubricantType,
        urlParam: "lubricant_type1_id",
      },
      {
        title: "Types of",
        apiRequest: getFiltersLubricantTypeById,
        urlParam: "lubricant_type2_id",
      },
    ],
    urlParam: "product_type_id",
  },
  {
    title: "Chemical",
    steps: [
      {
        title: "Types of",
        apiRequest: getFiltersChemicalType,
        urlParam: "chemical_type1_id",
      },
      {
        title: "Types of",
        apiRequest: getFiltersChemicalTypeById,
        urlParam: "chemical_type2_id",
      },
      {
        title: "Types of",
        apiRequest: getFiltersChemicalType3ById,
        urlParam: "chemical_type3_id",
      },
    ],
    urlParam: "product_type_id",
  },
];

const Products = () => {
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [fetchingProducts, setProductFetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPagination, setShowPagination] = useState(false);
  const [filterList, setFilterList] = useState(null);
  const [activeFilters, setActiveFilters] = useState([]);
  const [filterStepBranch, setFilterStepBranch] = useState("");
  const [filterUrlParams, setFilterUrlParams] = useState({});
  const [showFilters, setShowFilters] = useState(false);
  const [networkError, setNetworkError] = useState(false);

  const debouncedSearchName = useDebounce(searchValue, 400);
  const isMobile = useXBreakpoint(991);

  const handleFilterOptionChange = (option, depthLevel, activeFilter) => {
    const { id, title } = option;

    if (depthLevel === 0) {
      setFilterStepBranch(title);
    }

    const copyOfActiveFilters = activeFilters.filter(
      activeFilter => activeFilter.depthLevel <= depthLevel
    );

    const foundedActiveFilterByDepthLevel = activeFilters.findIndex(
      filter => filter.depthLevel === depthLevel
    );

    if (foundedActiveFilterByDepthLevel !== -1) {
      copyOfActiveFilters[foundedActiveFilterByDepthLevel] = { depthLevel, name: activeFilter };
    } else {
      copyOfActiveFilters.push({ depthLevel, name: activeFilter });
    }

    setActiveFilters([...copyOfActiveFilters]);

    const currentFilterTitle = depthLevel === 0 ? title : filterStepBranch;

    const filterBranch = filterSteps.find(filter => currentFilterTitle === filter.title);
    const filterBranchRequestProps = filterBranch.steps[depthLevel];

    const newFilterUrlParams = {};
    const whiteListFilterParams = ["product_type_id"];

    for (let i = 0; i < depthLevel; i++) {
      whiteListFilterParams.push(filterBranch.steps[i].urlParam);
    }

    for (const key in filterUrlParams) {
      if (filterUrlParams.hasOwnProperty(key)) {
        whiteListFilterParams.forEach(param =>
          param === key ? (newFilterUrlParams[key] = filterUrlParams[key]) : null
        );
      }
    }

    newFilterUrlParams[
      depthLevel !== 0 ? filterBranch.steps[depthLevel - 1].urlParam : filterBranch.urlParam
    ] = option.id;
    setFilterUrlParams(newFilterUrlParams);

    const storageFilters = JSON.stringify({
      activeFilters: copyOfActiveFilters,
      filterStepBranch,
      filterUrlParams: newFilterUrlParams,
    });

    localStorage.setItem("productsFilter", storageFilters);

    if (filterBranch.steps.length === depthLevel) {
      setShowFilters(false);
      return;
    }

    const filtersTitle =
      filterBranchRequestProps.title === "Types of"
        ? filterBranchRequestProps.title + " " + title
        : filterBranchRequestProps.title;

    const requestParams = filterUrlParams.brand_id ? [id, filterUrlParams.brand_id] : [id];

    return getFilterItems(filterBranchRequestProps.apiRequest, requestParams, title, filtersTitle);
  };

  const getFilterItems = async (request, requestParams, keyword, title) => {
    try {
      const response = await request(...requestParams);
      const responseFilterItems = response.data.filters.items;

      const clearedFilterList = filterList;
      for (const filter of clearedFilterList) {
        if (filter.title !== filterStepBranch && filter.items && filter.items.length) {
          filter.items = [];
        }
      }

      const newFilterListArray = recursivelyFindAndAdd(
        clearedFilterList,
        responseFilterItems,
        "items",
        "title",
        keyword,
        { filterTitle: title }
      );

      setFilterList([...newFilterListArray]);
      localStorage.setItem("productsFiltersRender", JSON.stringify(newFilterListArray));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    (async () => {
      const storedProductFilters = JSON.parse(localStorage.getItem("productsFilter"));
      const storedProductFiltersRender = JSON.parse(localStorage.getItem("productsFiltersRender"));

      if (storedProductFilters) {
        setFilterStepBranch(storedProductFilters.filterStepBranch);
        setActiveFilters(storedProductFilters.activeFilters);
        setFilterUrlParams(storedProductFilters.filterUrlParams);
      }

      if (storedProductFiltersRender) {
        setFilterList(storedProductFiltersRender);
      } else {
        const response = await getFiltersProductType();

        if (response.code === 200) {
          setFilterList(response.data.filters.items);
        } else {
          setNetworkError(true);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (
        JSON.parse(localStorage.getItem("productsFilter")) &&
        JSON.parse(localStorage.getItem("productsFilter")).filterUrlParams &&
        Object.keys(JSON.parse(localStorage.getItem("productsFilter")).filterUrlParams).length &&
        !Object.keys(filterUrlParams).length
      )
        return;

      try {
        if (currentPage === 1) setProductFetching(true);
        const stringifiedUrlParams = qs.stringify(filterUrlParams);
        const paramsWithName = `name=${debouncedSearchName}&` + stringifiedUrlParams;

        setLoading(true);

        const response = await getProductsByPage(paramsWithName, currentPage);
        if (response.code === 200) {
          setProducts(prevProducts =>
            currentPage > 1
              ? [...prevProducts, ...response.data.products.data]
              : response.data.products.data
          );
        } else {
          setNetworkError(true);
        }

        setTotalPages(response.data.products.last_page);
        setProductFetching(false);
        setLoading(false);
      } catch (error) {
        setProducts([]);
        setProductFetching(false);
        console.error(error);
      }
    })();
  }, [filterUrlParams, debouncedSearchName, currentPage]);

  useEffect(() => {
    setShowPagination(currentPage < totalPages);
  }, [currentPage, totalPages]);

  const loadMoreProducts = () => setCurrentPage(page => page + 1);

  const productList = products && products.length ? products : [];

  return (
    <Layout pageTitle="Products" withBottomPadding={false}>
      <Banner title="Engen Products" backgroundImage={productBannerImg} />
      <section className={styles["products-section"]}>
        {!isMobile && filterList && filterList.length && (
          <div className={styles.filters}>
            <ProductsFilter
              items={filterList}
              activeFilters={activeFilters}
              onChange={handleFilterOptionChange}
            />
          </div>
        )}
        <div className={styles.productsWrapper}>
          {!isMobile ? (
            <div className={styles.search}>
              <SearchInput
                name="search-contact"
                value={searchValue}
                key={"search-contact"}
                onChange={event => setSearchValue(event.target.value)}
                placeholder={"Name or code"}
                onClear={() => setSearchValue("")}
              />
            </div>
          ) : (
            <div className={styles.search}>
              <SearchInput
                name="search-contact"
                value={searchValue}
                key={"search-contact"}
                onChange={event => setSearchValue(event.target.value)}
                placeholder={"Name or code"}
                onClear={() => setSearchValue("")}
              />
              <div
                onClick={() => setShowFilters(!showFilters)}
                className={styles["search-icon"]}
                title="Open Filters">
                <RefineIcon />
              </div>
            </div>
          )}
          <div className={styles.productsContainer}>
            {isMobile && (
              <div
                className={`${styles.mobileFilters} ${
                  isMobile && showFilters ? styles.showFilters : ""
                }`}>
                <ProductsFilter
                  items={filterList}
                  activeFilters={activeFilters}
                  onChange={handleFilterOptionChange}
                />
              </div>
            )}
            <div className={styles.products}>
              {productList.length ? (
                productList.map(({ title, slug, image }) => (
                  <div key={slug} className={styles.product}>
                    <Product image={image && image.path} slug={slug} title={title} />
                  </div>
                ))
              ) : fetchingProducts ? (
                <Loader />
              ) : (
                <>{networkError ? <h2>Network Error</h2> : <h2>No products for your query</h2>}</>
              )}
            </div>
            {showPagination && (
              <div className={styles["load-more"]}>
                {loading ? <Loader /> : <Button text="Load More" onClick={loadMoreProducts} />}
              </div>
            )}
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Products;

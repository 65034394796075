import React from "react";
import styles from "./ServiceView.module.scss";
import { Layout, Loader, SectionList, Banner } from "../../components";
import { ReactComponent as Logo } from "../../static/images/pdf-file-logo.svg";
import useFetchB2B from "../../helpers/useFetchB2B";

const ServiceView = ({ match }) => {
  const { currentPage } = useFetchB2B({
    url: `b2b/${match.params.slug}/slug`,
    bySlug: true,
  });

  return (
    <Layout
      pageTitle={`${currentPage ? currentPage.title : ""}`}
      withBottomPadding={false}
      withScrollToTop>
      <Banner
        backgroundImage={currentPage && currentPage.image && currentPage.image.path}
        title={currentPage ? currentPage.header_title : ""}
      />
      <section className={styles.sections}>
        {currentPage ? (
          <>
            <div
              className={styles.description}
              dangerouslySetInnerHTML={{ __html: currentPage.description }}
            />
            <div className={styles.file}>
              {currentPage.file && (
                <a
                  key={currentPage.file.disk_name}
                  href={currentPage.file.path}
                  target="_blank"
                  rel="noopener noreferrer"
                  download={currentPage.file.file_name + ".pdf"}
                  className={styles["one-file"]}>
                  <Logo />
                  <span>{currentPage.file.title}</span>
                </a>
              )}
            </div>
            {currentPage.sections && currentPage.sections && currentPage.sections.length ? (
              <SectionList sections={currentPage ? currentPage.sections : []} />
            ) : null}
          </>
        ) : (
          <Loader />
        )}
      </section>
    </Layout>
  );
};

export default ServiceView;

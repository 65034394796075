import React from "react";
import { Layout, NavigationBanner, Section, SectionList } from "../../../components";
import styles from "./EngenCaresCsi.module.scss";
import useFetchB2B from "../../../helpers/useFetchB2B";
import b2bRoutes from "../../../helpers/b2b.routes";

const EngenCaresCsi = () => {
  const { sortedSection, currentPage, pages } = useFetchB2B({
    url: b2bRoutes.ENGENCARES,
  });

  return (
    <Layout pageTitle="Corporate Social Investment" withBottomPadding={false} backButton={true}>
      {currentPage ? (
        <>
          <NavigationBanner pages={pages} activePage={currentPage} />
          <div className={styles.main}>
            <div className={styles.bodySections}>
              <div className={styles.bodySectionsFlex}>
                <div className={styles.columnedBlock}>
                  {sortedSection[0] && <Section section={sortedSection[0]} />}
                  {sortedSection[1] && <Section section={sortedSection[1]} />}
                </div>

                <div className={styles.sectionsRight}>
                  <div className={styles.sectionsRightHealth}>
                    {sortedSection[2] && <Section section={sortedSection[2]} />}
                    {sortedSection[3] && <Section section={sortedSection[3]} />}
                  </div>
                  <div className={styles.sectionImage}>
                    {sortedSection[4] && <Section section={sortedSection[4]} />}
                  </div>
                </div>
              </div>

              <div className={styles.bodySectionsFlex}>
                <div className={styles.sectionsLeftText}>
                  {sortedSection[5] && <Section section={sortedSection[5]} />}
                  {sortedSection[6] && <Section section={sortedSection[6]} />}
                </div>
                {sortedSection[7] && <Section section={sortedSection[7]} />}
              </div>
            </div>
          </div>
          {sortedSection[8] && <SectionList sections={sortedSection.slice([8])} />}
        </>
      ) : null}
    </Layout>
  );
};

export default EngenCaresCsi;

import React, { useState, useRef, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import styles from "./ContactUsContacts.module.scss";
import { Info, SearchInput, Card, Button, FixedSearch } from "../../../components";
import { getContactList } from "../../../api/contacts.api";
import { searchContacts } from "../../../lib/search";
import useYBreakpoint from "../../../hooks/useYBreakpoint";

const ContactUsContacts = () => {
  const [contactList, setContactList] = useState([]);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [isShowAll, setIsShowAll] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [fixedSearchPosition, setFixedSearchPosition] = useState(null);

  const latestContactRef = useRef(null);
  const contactsGridRef = useRef(null);
  const showFixedSearch = useYBreakpoint(fixedSearchPosition);

  useEffect(() => {
    if (filteredContacts.length !== 0) {
      setFixedSearchPosition(contactsGridRef.current.offsetTop + 50);
    }
  }, [filteredContacts]);

  useEffect(() => {
    (async () => {
      try {
        const response = await getContactList();

        if (response.message === "success" && response?.data?.contacts) {
          setContactList(response?.data?.contacts);
          setFilteredContacts(response?.data?.contacts);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const loadMoreContacts = () => {
    setIsShowAll(true);

    setTimeout(() => {
      latestContactRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    });
  };

  useEffect(() => {
    contactList.length !== 0 &&
      setFilteredContacts(searchContacts(contactList, searchValue.toLowerCase()));
  }, [contactList, searchValue]);

  const handleSearchContact = event => {
    setSearchValue(event.target.value);
    if (isShowAll === false) return setIsShowAll(true);
  };

  const handleClearSearchInput = () => setSearchValue("");

  function createMarkup(title) {
    return { __html: title };
  }
  return (
    <section className={styles.contacts}>
      <div className={styles.search}>
        <SearchInput
          name="search-contact"
          value={searchValue}
          key={"search-contact"}
          onChange={handleSearchContact}
          placeholder="Search contact detail by country"
          onClear={handleClearSearchInput}
        />
      </div>
      {filteredContacts?.length === 0 ? (
        <div style={{ display: "flex", justifyContent: "center", paddingTop: "15px" }}>
          <p style={{ color: "rgb(93,93,93)", fontSize: "20px" }}>
            Cannot find results based on your query
          </p>
        </div>
      ) : (
        <div ref={contactsGridRef} className={styles["contacts-container"]}>
          {filteredContacts
            ?.filter((contact, index) => (!isShowAll && index < 3) || isShowAll)
            .map(
              ({
                slug,
                title,
                address,
                postal_address,
                contact_details,
                ethics_line,
                latitude,
                longitude,
              }) => (
                <Card style={{ position: "relative" }} key={slug} title={title}>
                  <div className={styles["card-container"]}>
                    <div className={styles["card-column"]}>
                      <Info label="Address">
                        <p dangerouslySetInnerHTML={createMarkup(address)}></p>
                        <p dangerouslySetInnerHTML={createMarkup(postal_address)}></p>
                      </Info>
                    </div>
                    <div className={styles["card-column"]}>
                      <Info label="Contact Details">
                        <p>{contact_details}</p>
                      </Info>
                      {ethics_line ? (
                        <Info label="Ethics Line">
                          <p>{ethics_line}</p>
                        </Info>
                      ) : null}
                    </div>
                  </div>
                </Card>
              )
            )}
          <div ref={latestContactRef}></div>
        </div>
      )}
      {!isShowAll && (
        <div className={styles["view-all-button"]}>
          <Button text="Show All" onClick={loadMoreContacts} />
        </div>
      )}
      <CSSTransition
        in={showFixedSearch}
        timeout={500}
        classNames="fixed-search"
        mountOnEnter
        unmountOnExit>
        <FixedSearch
          name="fixed-search-contact"
          key={"fixed-search-contact"}
          value={searchValue}
          onChange={handleSearchContact}
          placeholder="Search contact detail by country"
          onClear={handleClearSearchInput}
        />
      </CSSTransition>
    </section>
  );
};

export default ContactUsContacts;

import React, { useEffect, useState, useCallback } from "react";
import Cookies from "js-cookie";
import styles from "./Layout.module.scss";
import { Header, Footer, QuickLinksWidget, ChatBot } from "../index";
import { getUserCountry } from "../../api/stations.api";

const Layout = ({
  pageTitle = "Engen",
  withBottomPadding = true,
  withScrollToTop = true,
  backButton = false,
  children,
}) => {
  const [cookieAcc, setCookieAcc] = useState(true);

  useEffect(() => {
    if (!window.location.pathname.includes("products")) {
      localStorage.removeItem("productsFiltersRender");
      localStorage.removeItem("productsFilter");
    }

    document.title = pageTitle;

    if (withScrollToTop) {
      window.scrollTo(0, 0);
    }
  }, [pageTitle, withScrollToTop]);

  const fetchCountryCode = useCallback(async () => {
    try {
      const { country_code } = await getUserCountry();

      Cookies.set("countryCode", country_code, { expires: 1 });
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (!Cookies.get("countryCode")) {
      fetchCountryCode();
    }
  }, [fetchCountryCode]);

  return (
    <div style={!cookieAcc ? { paddingTop: "42px" } : {}} className={styles.wrapper}>
      <Header setCookieAcc={setCookieAcc} backButton={backButton} />
      <ChatBot />
      <main style={{ paddingBottom: withBottomPadding && "10rem" }} className={styles.main}>
        {children}
      </main>
      <Footer />
      <QuickLinksWidget />
    </div>
  );
};

export default Layout;

import React, { useEffect, useState } from "react";
import qs from "query-string";
import {
  Layout,
  NavigationBanner,
  Button,
  SearchInput,
  Loader,
  Product,
  RefineIcon,
  ProductsFilter,
} from "../../../components";
import styles from "./LubricantProducts.module.scss";
import {
  getFiltersBrand,
  getFiltersLubricantType,
  getFiltersLubricantTypeById,
  getLubricantProductsByPage,
} from "../../../api/products.api";
import useDebounce from "../../../hooks/useDebounce";
import recursivelyFindAndAdd from "../../../lib/recursivelyFindAndAdd";
import useXBreakpoint from "../../../hooks/useXBreakpoint";
import useFetchB2B from "../../../helpers/useFetchB2B";
import b2bRoutes from "../../../helpers/b2b.routes";

const filterSteps = [
  {
    title: "Brand",
    filterTitle: "Brand",
    steps: [
      {
        title: "Area of Use",
        apiRequest: getFiltersLubricantType,
        urlParam: "lubricant_type1_id",
      },
      {
        title: "Types of",
        apiRequest: getFiltersLubricantTypeById,
        urlParam: "lubricant_type2_id",
      },
    ],
    urlParam: "brand_id",
  },
];

const LubricantProducts = () => {
  const { currentPage: b2bPage, pages } = useFetchB2B({
    url: b2bRoutes.LUBRICANTS,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [products, setProducts] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [fetchingProducts, setProductFetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPagination, setShowPagination] = useState(false);
  const [filterList, setFilterList] = useState(null);
  const [filterUrlParams, setFilterUrlParams] = useState({});
  const [showFilters, setShowFilters] = useState(false);

  const isMobile = useXBreakpoint(991);

  const debouncedSearchName = useDebounce(searchValue, 400);

  const handleFilterOptionChange = (option, depthLevel, activeFilter) => {
    const { id, title } = option;

    const copyOfActiveFilters = activeFilters.filter(
      activeFilter => activeFilter.depthLevel <= depthLevel
    );

    const foundedActiveFilterByDepthLevel = activeFilters.findIndex(
      filter => filter.depthLevel === depthLevel
    );

    if (foundedActiveFilterByDepthLevel !== -1) {
      copyOfActiveFilters[foundedActiveFilterByDepthLevel] = { depthLevel, name: activeFilter };
    } else {
      copyOfActiveFilters.push({ depthLevel, name: activeFilter });
    }

    setActiveFilters([...copyOfActiveFilters]);

    const filterBranch = filterSteps[0];
    const filterBranchRequestProps = filterBranch.steps[depthLevel];

    const newFilterUrlParams = {};
    const whiteListFilterParams = ["brand_id"];

    for (let i = 0; i < depthLevel; i++) {
      whiteListFilterParams.push(filterBranch.steps[i].urlParam);
    }

    for (const key in filterUrlParams) {
      if (filterUrlParams.hasOwnProperty(key)) {
        whiteListFilterParams.forEach(param =>
          param === key ? (newFilterUrlParams[key] = filterUrlParams[key]) : null
        );
      }
    }

    newFilterUrlParams[
      depthLevel !== 0 ? filterBranch.steps[depthLevel - 1].urlParam : filterBranch.urlParam
    ] = option.id;
    setFilterUrlParams(newFilterUrlParams);

    const storageFilters = JSON.stringify({
      activeFilters: copyOfActiveFilters,
      filterUrlParams: newFilterUrlParams,
    });

    localStorage.setItem("lubricantProductsFilter", storageFilters);

    if (filterBranch.steps.length === depthLevel) {
      setShowFilters(false);
      return;
    }

    const filtersTitle =
      filterBranchRequestProps.title === "Types of"
        ? filterBranchRequestProps.title + " " + title
        : filterBranchRequestProps.title;

    const requestParams = filterUrlParams.brand_id ? [id, filterUrlParams.brand_id] : [id];

    return getFilterItems(filterBranchRequestProps.apiRequest, requestParams, title, filtersTitle);
  };

  const getFilterItems = async (request, requestParams, keyword, title) => {
    try {
      const response = await request(...requestParams);
      const responseFilterItems = response.data.filters.items;

      const newFilterListArray = recursivelyFindAndAdd(
        filterList,
        responseFilterItems,
        "items",
        "title",
        keyword,
        { filterTitle: title }
      );

      setFilterList([...newFilterListArray]);
      localStorage.setItem("lubricantProductsFiltersRender", JSON.stringify(newFilterListArray));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    (async () => {
      const storedProductFilters = JSON.parse(localStorage.getItem("lubricantProductsFilter"));
      const storedProductFiltersRender = JSON.parse(
        localStorage.getItem("lubricantProductsFiltersRender")
      );

      if (storedProductFilters) {
        setActiveFilters(storedProductFilters.activeFilters);
        setFilterUrlParams(storedProductFilters.filterUrlParams);
      }

      if (storedProductFiltersRender) {
        setFilterList(storedProductFiltersRender);
      } else {
        const response = await getFiltersBrand();

        setFilterList(response.data.filters.items);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (
        JSON.parse(localStorage.getItem("lubricantProductsFilter")) &&
        JSON.parse(localStorage.getItem("lubricantProductsFilter")).filterUrlParams &&
        Object.keys(JSON.parse(localStorage.getItem("lubricantProductsFilter")).filterUrlParams)
          .length &&
        !Object.keys(filterUrlParams).length
      )
        return;

      try {
        if (currentPage === 1) setProductFetching(true);
        const stringifiedUrlParams = qs.stringify(filterUrlParams);
        const paramsWithName = `name=${debouncedSearchName}&` + stringifiedUrlParams;

        setLoading(true);

        const response = await getLubricantProductsByPage(paramsWithName, currentPage);

        setProducts(prevProducts =>
          currentPage > 1
            ? [...prevProducts, ...response.data.products.data]
            : response.data.products.data
        );
        setTotalPages(response.data.products.last_page);
        setProductFetching(false);
        setLoading(false);
      } catch (error) {
        setProducts([]);
        setProductFetching(false);
        console.error(error);
      }
    })();
  }, [filterUrlParams, debouncedSearchName, currentPage]);

  useEffect(() => {
    setShowPagination(currentPage < totalPages);
  }, [currentPage, totalPages]);

  const loadMoreProducts = () => setCurrentPage(page => page + 1);

  const productList = products && products.length ? products : [];

  return (
    <Layout pageTitle="Lubricant Products" withBottomPadding={false} backButton={true}>
      {b2bPage && (
        <>
          <NavigationBanner pages={pages} activePage={b2bPage} />
          <section className={styles["products-section"]}>
            {!isMobile && (
              <div className={styles.filters}>
                <ProductsFilter
                  items={filterList}
                  activeFilters={activeFilters}
                  onChange={handleFilterOptionChange}
                />
              </div>
            )}
            <div className={styles.productsWrapper}>
              {!isMobile ? (
                <div className={styles.search}>
                  <SearchInput
                    name="search-contact"
                    value={searchValue}
                    key={"search-contact"}
                    onChange={event => setSearchValue(event.target.value)}
                    placeholder={"Name or code"}
                    onClear={() => setSearchValue("")}
                  />
                </div>
              ) : (
                <div className={styles.search}>
                  <SearchInput
                    name="search-contact"
                    value={searchValue}
                    key={"search-contact"}
                    onChange={event => setSearchValue(event.target.value)}
                    placeholder={"Name or code"}
                    onClear={() => setSearchValue("")}
                  />
                  <div
                    onClick={() => setShowFilters(!showFilters)}
                    className={styles["search-icon"]}
                    title="Open Filters">
                    <RefineIcon />
                  </div>
                </div>
              )}
              <div className={styles.productsContainer}>
                {isMobile && (
                  <div
                    className={`${styles.mobileFilters} ${
                      isMobile && showFilters ? styles.showFilters : ""
                    }`}>
                    <ProductsFilter
                      activeFilters={activeFilters}
                      items={filterList}
                      onChange={handleFilterOptionChange}
                    />
                  </div>
                )}
                <div className={styles.products}>
                  {productList.length ? (
                    productList.map(({ title, slug, image }) => (
                      <div key={slug} className={styles.product}>
                        <Product image={image && image.path} slug={slug} title={title} />
                      </div>
                    ))
                  ) : fetchingProducts ? (
                    <Loader />
                  ) : (
                    <h2>No products for your query</h2>
                  )}
                </div>
                {showPagination && (
                  <div className={styles["load-more"]}>
                    {loading ? <Loader /> : <Button text="Load More" onClick={loadMoreProducts} />}
                  </div>
                )}
              </div>
            </div>
          </section>
        </>
      )}
    </Layout>
  );
};

export default LubricantProducts;

import React, { useLayoutEffect } from "react";
import Stickyfill from "stickyfilljs";
import styles from "./ProductsFilter.module.scss";
import { Radio } from "../index";

const FilterBlock = ({ title, items, activeFilters = [], onChange, depthLevel = 0, prevTitle }) => {
  const handleSetFilter = option => {
    const activeFilter = option.id + option.title + prevTitle;
    onChange(option, depthLevel, activeFilter);
  };

  const activeFilterItem = items.find(
    ({ id, title }) => !!activeFilters.find(({ name }) => name === id + title + prevTitle)
  );

  return (
    <>
      <div className={styles.filterItemWrapper}>
        <h5 className={styles.filterItemTitle}>{title && title + ":"}</h5>
        <div className={styles.filterItemGroup}>
          {items &&
            items.length &&
            items.map(item => (
              <Radio
                key={item.id + item.title}
                id={item.id + item.title}
                value={item.id}
                name={item.title}
                label={item.title}
                color="white"
                checked={
                  !!activeFilters.find(({ name }) => name === item.id + item.title + prevTitle)
                }
                onChange={() => handleSetFilter(item)}
              />
            ))}
        </div>
      </div>
      {activeFilterItem && activeFilterItem.items && activeFilterItem.items.length ? (
        <FilterBlock
          title={activeFilterItem.filterTitle}
          prevTitle={activeFilterItem.title}
          items={activeFilterItem.items}
          activeFilters={activeFilters}
          onChange={onChange}
          depthLevel={depthLevel + 1}
        />
      ) : null}
    </>
  );
};

const ProductsFilter = ({ title, items, activeFilters, onChange }) => {
  useLayoutEffect(() => {
    Stickyfill.refreshAll();
  }, []);

  const stickyElements = document.querySelectorAll(".sticky");
  Stickyfill.add(stickyElements);

  return (
    <div className={styles.wrapper}>
      <div className={`sticky ${styles.filters}`}>
        <div className={styles.filterHeight}>
          <h3 className={styles.title}>Filters</h3>
          {items && items.length && (
            <FilterBlock
              items={items}
              activeFilters={activeFilters}
              onChange={onChange}
              title={title}
              prevTitle=""
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductsFilter;

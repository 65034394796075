import React from "react";
import { Layout, Banner, Section, Button } from "../../components";
import styles from "./Careers.module.scss";
import useFetchB2B from "../../helpers/useFetchB2B";

const Careers = () => {
  const { sortedSection, currentPage } = useFetchB2B({
    url: "b2b/careers/slug",
    bySlug: true,
  });

  return (
    <Layout withBottomPadding={false} pageTitle="Careers">
      {currentPage ? (
        <>
          <Banner
            backgroundImage={currentPage && currentPage.image ? currentPage.image.path : ""}
            title="Careers"
          />
          <div className={styles.main}>
            {sortedSection[0] && (
              <Section section={sortedSection[0]}>
                <a
                  style={{ display: "block", marginTop: "7rem" }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://careers.engenoil.com/">
                  <Button isWide={false} classes="white" text="Visit Careers Portal" />
                </a>
              </Section>
            )}
            {sortedSection[1] && <Section section={sortedSection[1]} />}
            {sortedSection[2] && <Section section={sortedSection[2]} />}
            {sortedSection[3] && <Section section={sortedSection[3]} />}
            <div className={styles.imageSideFlex}>
              {sortedSection[4] && <Section section={sortedSection[4]} />}
              <div className={styles.sideContent}>
                {sortedSection[5] && <Section section={sortedSection[5]} />}
                {sortedSection[6] && <Section section={sortedSection[6]} />}
              </div>
            </div>
            {sortedSection[7] && <Section section={sortedSection[7]} />}
            {sortedSection[8] && <Section section={sortedSection[8]} />}
            {sortedSection[9] && <Section section={sortedSection[9]} />}
            {sortedSection[10] && <Section section={sortedSection[10]} />}
            {sortedSection[11] && <Section section={sortedSection[11]} />}
            {sortedSection[12] && <Section section={sortedSection[12]} />}
            {sortedSection[13] && <Section section={sortedSection[13]} />}
            {sortedSection[14] && <Section section={sortedSection[14]} />}
            {sortedSection[15] && <Section section={sortedSection[15]} />}
            {sortedSection[16] && <Section section={sortedSection[16]} />}
            {sortedSection[17] && <Section section={sortedSection[17]} />}
            {sortedSection[18] && <Section section={sortedSection[18]} />}
            {sortedSection[19] && <Section section={sortedSection[19]} />}
            {sortedSection[20] && <Section section={sortedSection[20]} />}
          </div>
        </>
      ) : null}
    </Layout>
  );
};

export default Careers;

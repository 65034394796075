import React from "react";
import { Layout, NavigationBanner } from "../../components";
import useFetchB2B from "../../helpers/useFetchB2B";
import b2bRoutes from "../../helpers/b2b.routes";

const Faq = () => {
  const { currentPage, pages } = useFetchB2B({
    url: b2bRoutes.FAQ,
    mainPage: true,
  });

  return (
    <Layout pageTitle="FAQ's" withBottomPadding={false}>
      {currentPage ? <NavigationBanner pages={pages} activePage={currentPage} /> : null}
    </Layout>
  );
};

export default Faq;

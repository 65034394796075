import React, { useState, useEffect } from "react";
import { Layout, NavigationBanner, Tabs, Carousel, CuttedCarouselItem } from "../../../components";
import styles from "./AboutLeadership.module.scss";
import useFetchB2B from "../../../helpers/useFetchB2B";
import b2bRoutes from "../../../helpers/b2b.routes";

const AboutOwnership = () => {
  const { currentPage, pages } = useFetchB2B({ url: b2bRoutes.ABOUT });

  const [sections, setSections] = useState(null);
  const [activeTab, setActiveTab] = useState(null);

  useEffect(() => {
    if (currentPage && currentPage.sections) {
      const splittedSections = {
        engenLimitedBoard: [],
        management: [],
        content: [],
      };

      for (const section of currentPage.sections) {
        if (section.slug.includes("person")) {
          if (section.slug.includes("executive") || section.slug.includes("independent")) {
            splittedSections.engenLimitedBoard.push(section);
          } else {
            splittedSections.management.push(section);
          }
        } else {
          splittedSections.content.push(section);
        }
      }

      setSections(splittedSections);
      setActiveTab("engenLimitedBoard");
    }
  }, [currentPage]);

  const tabs = [
    {
      id: "engenLimitedBoard",
      value: "engenLimitedBoard",
      label: "Engen Limited Board",
    },
    {
      id: "management",
      value: "management",
      label: "Engen Petroleum Limited Management Committee",
    },
  ];

  const handleChangeActiveTab = value => {
    setActiveTab(value);
  };

  return (
    <Layout pageTitle="Leadership" withBottomPadding={false} backButton={true}>
      {sections ? (
        <>
          <NavigationBanner pages={pages} activePage={currentPage} />
          <section className={styles.tabs}>
            <Tabs tabs={tabs} activeTab={activeTab} action={handleChangeActiveTab} />
            <h2 className={`title ${styles.tabTitle}`}>
              {tabs.find(({ value }) => value === activeTab).label}
            </h2>
          </section>
          <section className={styles.carousel}>
            <Carousel
              slides={sections[activeTab]}
              render={(slides, activeSlide) =>
                slides.map((person, index) => {
                  return (
                    <CuttedCarouselItem
                      person={person}
                      key={person.id}
                      activePost={activeSlide === index}
                    />
                  );
                })
              }
            />
          </section>
        </>
      ) : null}
    </Layout>
  );
};

export default AboutOwnership;
